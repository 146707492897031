@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@mixin scrollbars(
  $size,
  $foreground-color,
  $hover-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
.bounty_template_full_screen {
  width: calc(100vw - 35px);
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: "Poppins";
}
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Poppins";
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
button {
  outline: none;
}

.bounty_Progress_Status {
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 25%;
  right: 25%;
  width: 50%;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: transparent;
  height: 20px;
}

.bounty_myprogressBar {
  width: 1%;
  height: 20px;
  background-color: #44e2d0;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 16px;
}

.bounty_double_bounce1,
.bounty_double_bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #44e2d0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.bounty_double_bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.bounty_spinner {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  display: none;
}
.bounty_loading_title {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  width: 300px;
  color: #ffffff;
  text-align: center;
  margin-top: 50px;
  font-size: 12px;
  font-family: "Poppins";
}
.bounty_viewer_not_found {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  width: 50%;
  text-align: center;
  display: none;
}
.bounty_viewer_not_found_text {
  font-size: 16px;
}

.bounty_thewiewerwindow {
  background-color: #ffffff;
  position: relative;
  // width: 100%;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bounty_rendercanvas {
  grid-column: 1;
  grid-row: 2;
  width: 100% !important;
  height: 100% !important;
  display: inline-block;
  background-color: #464646;
  outline: none;
  overflow: hidden;
  touch-action: none;
  position: relative;
}
.bounty_grid_bg {
  background-color: #464646;
}

.bounty_cursor_grab {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
.bounty_cursor_move {
  cursor: move;
}
.bounty_cursor_zoomIn {
  cursor: zoom-in;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
}
.bounty_cursor_zoomOut {
  cursor: zoom-out;
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
}
.bounty_nav_container {
  grid-row: 1;
  z-index: 1;
  display: flex;
  height: max-content;
  position: relative;
  padding: 3px 12px 3px 12px;
  //grid-template-columns: 1fr auto auto auto 1fr;
  border-top: solid #c8c8ca 1px;
  border-bottom: solid #c8c8ca 1px;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 839px) {
    overflow-x: auto;
    overflow-y: hidden;
    @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
  }
}
.bounty_nav_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: flex-start;

  cursor: pointer;
}
.bounty_nav_controls_icon {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
  height: 100%;
  border-left: 2px solid #ffffff;
  display: grid;
  justify-items: center;
  margin-left: 12px;
  align-items: center;
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 1s;
      visibility: visible;
      font-weight: 500;
    }
  }
}
.bounty_total_steps {
  display: flex;
  color: #2e2e2f;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
}

.bounty_nav_views {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  margin-inline-start: auto;
}
.bounty_nav_views_icon {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;

  display: grid;
  justify-items: center;
  border-left: 2px solid #ffffff;
  margin-left: 12px;

  height: 100%;
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 1s;
      visibility: visible;
      font-weight: 500;
    }
  }
}
.bounty_nav_views_icon_active {
  border-bottom: 5px solid #00c7b1;
  font-weight: 700;
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
      stroke: #2e2e2f;
    }
  }
}
.bounty_nva_options {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-inline-end: auto;
  justify-content: center;
  cursor: pointer;
}

.bounty_nva_options_icon {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
  height: 100%;
  display: grid;
  border-left: 2px solid #ffffff;
  justify-items: center;
  text-align: center;
  margin-left: 12px;
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 1s;
      visibility: visible;
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 1405px) {
    margin-inline-end: unset;
    margin: 0 6px;
  }
}

.bounty_tools_icon {
  position: relative;
  margin: auto 0;
  font-size: 12px;
  line-height: 18px;
  display: grid;
  padding: 4px 12px;
  justify-items: start;
}

.bounty_tools_arrow {
  padding-top: 12px;
  padding-left: 2px;
}

.bounty_dropdown_content {
  position: absolute;
  width: max-content;
  display: flex;
  z-index: 1;
  //top: 140px;
  bottom: -85px;
  left: -45px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: auto;
  padding: 8px 0px;
  .bounty_nva_options_icon {
    margin: 0px 8px;
  }
}

.bounty_dropdown_content:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  bottom: 73%;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
}

.bounty_dropdown_container {
  position: absolute;
  background-color: #00000000;
  height: 100px;
  width: 50px;
  margin: 0 auto;
}

.bounty_tooltip_content {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 76px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: auto;
  padding: 8px;
  width: max-content;
  height: max-content;
}

.bounty_tooltip_content:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  bottom: 75%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
}

.bounty_tooltip_content_options {
  top: 70px;
}

.bounty_rotate_active {
  border-bottom: 5px solid #00c7b1;
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
    }
  }
}
.bounty_pan_active {
  border-bottom: 5px solid #00c7b1;
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
    }
  }
}
.bounty_zoomIn_active {
  border-bottom: 5px solid #00c7b1;
  svg {
    .bounty_prime_color_svg {
      stroke: #2e2e2f;
    }
  }
}
.bounty_zoomOut_active {
  border-bottom: 5px solid #00c7b1;
  svg {
    .bounty_prime_color_svg {
      stroke: #2e2e2f;
    }
  }
}

.bounty_options_active {
  border-bottom: 5px solid #00c7b1;
  font-weight: 700;
  svg {
    .bounty_prime_color_svg {
      stroke: #2e2e2f;
    }
  }
}
.bounty_ipr_active {
  border-bottom: 5px solid #00c7b1;
  font-weight: 700;
  svg {
    .bounty_prime_color_svg {
      fill: #2e2e2f;
    }
  }
}

#bounty_steps_container {
  width: 98%;
  display: flex;
  margin: auto;
  height: 60px;
  overflow-x: hidden;
  overflow-y: hidden;
  justify-self: center;
  z-index: -1;
  //position: relative;
  background-color: #ffffff;
  @media (max-width: 839px) {
    height: 0%;
  }
}

.bounty_steps_content {
  width: 100%;
  display: grid;
  position: relative;
  cursor: pointer;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(auto-fit, minmax(5px, auto));
}

.bounty_steps {
  row-gap: 8px;
  display: grid;
  align-self: center;
  height: 60px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  grid-template-columns: 50px 1fr;
}

.bounty_step {
  background-color: #2e2e2f;
  height: 5px;
  width: 100%;
  cursor: pointer;
}

.bounty_emptyStep {
  background-color: transparent !important;
  height: 5px;
  width: 100%;
  cursor: default;
}

.bounty_passiveAligners {
  background-color: #cccccc !important;
  height: 5px;
  width: 100%;
  cursor: pointer;
}

.bounty_firstStep {
  background-color: #2e2e2f;
  height: 5px;
  cursor: pointer;
  width: 100%;
}

.bounty_overCorrectionStepsNumber {
  height: 5px;
  width: 100%;
  cursor: pointer;
  background-color: #d90429 !important;
}

.bounty_labels_content {
  position: relative;
  width: 100%;
  height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins";
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bounty_label_upper {
  width: 100%;
  color: #2e2e2f;
  font-size: 14px;
  z-index: 22;
  font-weight: 500;
  text-align: center;
  align-self: center;
  min-width: 25px;
  cursor: pointer;
  font-family: "Poppins";
}
.bounty_current_step {
  position: absolute;
  z-index: 12;
  top: 8px;
  font-size: 14px;
  line-height: 10.2px;
  font-weight: 600;
  color: #000000;
  border-radius: 4px;
  padding: 4px;
  width: 18px;
  height: 10px;
  text-align: center;
}
.bounty_tooltip_upper {
  position: absolute;
  z-index: 12;
  top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 10.2px;
  background: #000000;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px;
  width: 22px;
  height: 12px;
  text-align: center;
}
.bounty_tooltip_upper:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  bottom: -25%;
  z-index: -2;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #000000;
}

.bounty_first_label_upper {
  opacity: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  align-self: center;
  min-width: 25px;
  z-index: 8;
  cursor: default;
  font-family: "Poppins";
}

.bounty_play_pause_container {
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  .bounty_play_puase_prime_color_svg {
    fill: #44e2d0;
  }
  div:active {
    svg {
      .bounty_prime_color_svg {
        fill: #292d32;
      }
    }
  }
  @media (max-width: 839px) {
    bottom: 0%;
    left: 25%;
  }
}
.bounty_play_pause_container_big_steps {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 7%;
  left: 45%;
  background-color: #ffff;
  border-radius: 5px 5px 0px 0px;
  padding: 8px 8px 4px 8px;
  height: 40px;
  .bounty_play_puase_prime_color_svg {
    fill: #44e2d0;
  }
  div:active {
    svg {
      .bounty_prime_color_svg {
        fill: #44e2d0;
      }
    }
  }
  @media (max-width: 839px) {
    bottom: 0%;
    left: 25%;
    position: fixed;
  }
}

.bounty_steps_controls {
  padding: 0px 4px;
}
.bounty_step_tooltip::after {
  content: "";
  position: absolute;
  z-index: 12;
  top: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 10.2px;
  background: #000000;
  color: #ffffff;
  border-radius: 4px;
  padding: 8px 4px;
  left: 25%;
  right: 25%;
  margin: 0 auto;
  text-align: center;
}
.bounty_active_step {
  background-color: #e4e4e4;
  margin: auto 0;
  position: relative;
  min-width: 40px;

  .bounty_step {
    width: 80%;
    margin: 0 auto;
  }
  .bounty_emptyStep {
    width: 80%;
    margin: 0 auto;
  }
  .bounty_firstStep {
    width: 80%;
    margin: 0 auto;
  }
  .bounty_overCorrectionStepsNumber {
    width: 80%;
    margin: 0 auto;
  }
  .bounty_passiveAligners {
    width: 80%;
    margin: 0 auto;
  }
}
.bounty_step_div {
  display: grid;
  flex-direction: row;
  align-items: center;
  height: 55px;
  align-content: space-evenly;
}
.bounty_nav_icon_full_screen {
  display: grid;
  justify-items: flex-end;
  cursor: pointer;
  margin-inline-start: auto;
  width: 50px;
}
.bounty_divider {
  content: "";
  width: 2px;
  height: 34px;
  background-color: #c8c8ca;
  display: grid;
  margin: 0px 40px;
}
.bounty_tools_div {
  svg {
    transform: rotate(270deg);
  }
  display: flex;
}
.bounty_screen_divider {
  content: "";
  border-bottom: 4px solid #ffffff;
  transform: rotate(90deg);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0%;
  z-index: 2;
}

.bounty_initial_plan {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 85%;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  left: 22%;
}
.bounty_final_result {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 85%;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  right: 22%;
}
.bounty_canvas_container {
  display: contents;
  position: absolute;
}
.bounty_close_split {
  position: absolute;
  top: 80px;
  right: 2%;
  cursor: pointer;
}
.bounty_options_disabled {
  opacity: 0.4;
  cursor: not-allowed;
  text-align: center;
}

/********************label **********************/

.bounty-tooltip-sprite {
  width: 140px;
  max-width: 140px;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background-color: rgb(255, 255, 255);
  color: #2e2e2f;
  text-align: left;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  top: -1em;
  padding: 8px 16px;
  font-family: "Poppins";
  z-index: 999999;
}
.bounty-tooltip-sprite::after {
  font-family: "Poppins";
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: rgb(253, 250, 250) transparent transparent transparent;
}

.bounty-lower-tooltip-sprite {
  font-family: "Poppins";
  width: 140px;
  max-width: 140px;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background-color: rgb(255, 255, 255);
  color: #2e2e2f;
  text-align: left;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  top: 6em;
  padding: 8px 16px;

  z-index: 999999;
}

.bounty-lower-tooltip-sprite::after {
  font-family: "Poppins";
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}
.hovered {
  cursor: pointer;
}

.bounty_tooltip_no_ipr {
  opacity: 1;
  position: absolute;
  top: unset;
  bottom: -95%;
}

#viewer-window {
  position: relative;
  font-family: "Poppins" !important;
}
